import { ATTENDANCE_COLUMNS } from "./attendance.column";
import { FEEDBACK_COLUMNS } from "./feedback.column";
import { PROJECTS_COLUMNS } from "./project.column";
import { STUDENTS_COLUMNS } from "./students.columns";
import { TASK_REPORT_COLUMNS } from "./taskReport.column";
import { EMPMAPPING_COLUMNS } from "./empMapping.column";
import { TASK_STATUS_COLUMNS } from "./taskStatus.column";
import { EMP_WORK_COLUMNS } from "./empWork.column";
import { PROJECT_WORK_COLUMNS } from "./projectWork.column";
import { LEAVE_POLICY_COLUMNS } from "./leavePolicy.column";
import { SINGLE_EMP_WORK_COLUMNS } from "./singleEmpWork.column";
import { SPRINT_COLUMNS } from "./sprint.column";
import { STORY_COLUMNS } from "./story.column";
import { EMAIL_SEND_COLUMNS } from "./emailSend.column";
import { NOTIFICATION_SEND_COLUMNS } from "./NotificationSend.column";
import { WORKING_TASKS_COLUMNS } from "./workingTasks.column";
import { PROJECT_TASK_COLUMNS } from "./tasks.column";
import { PROJECT_WORK_HOURS_COLUMNS } from "./projectWorkHours.columns";
import { MONTH_WISE_TASK_TABLE } from "./monthTask.column";
import { WORKING_HOUR_COLUMNS } from "./workingHour.column";
import { MENU_COLUMNS } from "./menu.column";
import { MONTHLY_PROJECT_HOURS } from "./monthlyWorkHour.column";

export const COLUMNS = {
  student: STUDENTS_COLUMNS,
  attendace: ATTENDANCE_COLUMNS,
  projects: PROJECTS_COLUMNS,
  feedback: FEEDBACK_COLUMNS,
  taskReport: TASK_REPORT_COLUMNS,
  empMapping: EMPMAPPING_COLUMNS,
  taskStatus: TASK_STATUS_COLUMNS,
  workingTask: WORKING_TASKS_COLUMNS,
  empWork: EMP_WORK_COLUMNS,
  projectWork: PROJECT_WORK_COLUMNS,
  leavePolicy: LEAVE_POLICY_COLUMNS,
  singleEmpWorkingDetail: SINGLE_EMP_WORK_COLUMNS,
  sprint: SPRINT_COLUMNS,
  story: STORY_COLUMNS,
  emailSend: EMAIL_SEND_COLUMNS,
  notificationSend: NOTIFICATION_SEND_COLUMNS,
  workingloss :WORKING_HOUR_COLUMNS,
  menu:MENU_COLUMNS,
  monthlyProjectHour:MONTHLY_PROJECT_HOURS,
  taskColumns: PROJECT_TASK_COLUMNS,
  projectWorkHours: PROJECT_WORK_HOURS_COLUMNS,
  monthsWiseTasks: MONTH_WISE_TASK_TABLE,
};
