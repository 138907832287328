import { apiUrls } from "../../api-urls";
import { request } from "../../../services/axios.service";
import { useMutation } from "react-query";
import { TMenuOrder } from "../../../form/initial-value/menu.values";

const updateMenuOrder = async (data: { menuItems: TMenuOrder[] }) => {
  const response: TServerResponse<unknown, unknown> = await request({
    url: apiUrls.menu.UPDATE_MENU_ORDER,
    method: "POST",
    data: data,
  });
  return response;
};

export const useUpdateMenuOrder = () => {
  return useMutation({ mutationFn: updateMenuOrder });
};
