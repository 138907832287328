import { Box, Text, TextInput, TextInputProps } from "@mantine/core";
import { GetInputProps } from "@mantine/form/lib/types";
import React, { memo } from "react";

interface IInputField {
  label?: string;
  getInputProps: GetInputProps<TextInputProps>;
  name: string;
  type?: TextInputProps["type"];
  disabled?: boolean;
  placeHolder?: string;
  description?: string;
}

const InputField: React.FC<IInputField> = ({
  label,
  getInputProps,
  name,
  type = "text",
  disabled,
  description,
  placeHolder = "",
}) => {
  return (
    <Box>
      <Text mb={15} style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}>
        {label}
      </Text>
      <TextInput
        type={type}
        placeholder={placeHolder}
        {...getInputProps(name)}
        disabled={disabled}
        description={description}
      />
    </Box>
  );
};

export default memo(InputField);
