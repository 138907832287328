import { ActionIcon, Box, Button, Group, Modal, Text, Tooltip } from "@mantine/core";
import {
  IconEdit,
  IconTrash,
} from "@tabler/icons-react";
import React, { FC, memo } from "react";
import { showNotification } from "@mantine/notifications";
import { useDisclosure } from "@mantine/hooks";
import { useDeleteMenu } from "../../../hooks/menu/mutation/deleteMenu.mutation";

interface IActionButton {
  row: TMenuData;
  handleClick: () => void;
  refresh: () => void;
}

const ActionButton: FC<IActionButton> = ({ handleClick, refresh, row }) => {
  const [opened, { open, close }] = useDisclosure(false);
  const { isLoading, mutateAsync } = useDeleteMenu();
  

  const handleDeleteAction = async () => {
    if (!row._id) {
      showNotification({
        message: "Menu Id is missing. Cannot delete Menu.",
        color: "red",
      });
      return;
    }
    const res = await mutateAsync(row._id);
    if (res.status === "success") {
      refresh();
      showNotification({
        message: res.message,
        color: "green",
      });
    } else {
      showNotification({
        message: res.message,
        color: "red",
      });
    }
  };

  return (
    <Box>
       <Modal
        opened={opened}
        onClose={close}
        title="Confirm Deletion"
        centered
        styles={{ title: { fontWeight: 600 } }}
      >
        <Text size="md" fw={400} ta="center">
          Are you sure you want to delete this menu? This action cannot be
          undone.
        </Text>
        <Group position="center" mt="md">
          <Button color="red" onClick={handleDeleteAction} loading={isLoading}>
            Yes
          </Button>
          <Button variant="outline" onClick={close} disabled={isLoading}>
            No
          </Button>
        </Group>
      </Modal>
      <Group>
      <Tooltip label="Edit Menu">
        <ActionIcon
          variant="outline"
          color="blue"
          onClick={() => {
            handleClick();
          }}
        >
          <IconEdit />
        </ActionIcon>
      </Tooltip>
      <Tooltip label="Delete Menu">
          <ActionIcon
            variant="outline"
            color="red"
            onClick={open}
            disabled={isLoading}
          >
            <IconTrash />
          </ActionIcon>
        </Tooltip>
      
    </Group>
    </Box>
   
  );
};

export default memo(ActionButton);
