import { Box, createStyles, Group, Menu } from "@mantine/core";
import React from "react";
import { Link } from "react-router-dom";
import IMAGES from "../../images";

interface IProps {
  menuData: TMenuData[];
}

const TopHeader = ({ menuData }: IProps) => {
  const { classes } = useStyles();

  return (
    <Box style={{ overflow: "auto" }}>
      <Group spacing="xs" className={classes.borderBottom}>
        <img src={IMAGES.logo} alt={"logo"} className={classes.image} />
        <Box>
          <Group>
            {menuData.map((item, index) => (
              <Group spacing="xs" key={item.title + index}>
                {item.submenu && item.submenu.length > 0 ? (
                  <Menu
                    trigger="hover"
                    position="bottom-start"
                    offset={4}
                    zIndex={300}
                  >
                    <Menu.Target>
                      <Link
                        to={item.link}
                        className={classes.link}
                        target={item.openInNewTab ? "_blank" : "_self"}
                      >
                        {item.title}
                      </Link>
                    </Menu.Target>
                    <Menu.Dropdown>
                      {item.submenu.map((subItem, subIndex) => (
                        <Menu.Item key={subItem.title + subIndex}>
                          <Link
                            to={subItem.link}
                            className={classes.link}
                            target={item.openInNewTab ? "_blank" : "_self"}
                          >
                            {subItem.title}
                          </Link>
                        </Menu.Item>
                      ))}
                    </Menu.Dropdown>
                  </Menu>
                ) : (
                  <Link
                    to={item.link}
                    className={classes.link}
                    target={item.openInNewTab ? "_blank" : "_self"}
                  >
                    {item.title}
                  </Link>
                )}
              </Group>
            ))}
          </Group>
        </Box>
      </Group>
    </Box>
  );
};

export default TopHeader;

const useStyles = createStyles(() => ({
  borderBottom: {
    borderBottom: "1px solid rgba(128, 128, 128, 0.25)",
    width: "100%",
    padding: "6px 10px",
    overflow: "auto",
    "@media (max-width: 1000px)": {
      minWidth: 1000,
    },
  },
  link: {
    color: "#000",
    textDecoration: "none",
    fontSize: 12,
    paddingTop: 0.5,
  },
  image: {
    width: 18,
    height: 18,
  },
}));
