import React, { useMemo, useRef } from "react";
import { Box } from "@mantine/core";
import MenuModal, { IMenuModalRef } from "./modal/MenuModal";
import ThemeButton from "../../component/button/ThemeButton";
import { IconPlus } from "@tabler/icons-react";
import CustomTable from "../../component/table";
import { COLUMNS } from "../../columns";
import { useGetMenu } from "../../hooks/menu/query/getMenu.query";
import ActionButton from "./components/ActionButton";
import { useUpdateMenuOrder } from "../../hooks/menu/mutation/updateMenuOrder.mutation";
import { notifications } from "@mantine/notifications";

const Menu = () => {
  const modalRef = useRef<IMenuModalRef>(null);
  const tableColumns = [...COLUMNS.menu];

  const { data, isLoading, refetch } = useGetMenu();
  const {isLoading:UpdateLoading, mutateAsync: updateMenuOrder } = useUpdateMenuOrder();

  const menuData: TMenuData[] = useMemo(() => {
    if (!isLoading && data && data.data) {
      return data.data.map((menu) => ({
        ...menu,
      }));
    } else {
      return [];
    }
  }, [isLoading, data]);

  tableColumns.push({
    header: "Employee Actions",
    key: "editStudent",
    renderCell: (row: TMenuData) => (
      <ActionButton
        handleClick={() => {
          modalRef.current?.toggleModal();
          modalRef.current?.updateData(row);
        }}
        refresh={refetch}
        row={row}
      />
    ),
  });

  const handleUpdateMenuOrder = async (
    updatedOrder: { _id: string; order: number }[]
  ) => {    
   try {
      const res = await updateMenuOrder({ menuItems: updatedOrder });

      if (res.status === "success") {
        refetch()
        notifications.show({
          color: "green",
          message: res.message,
        });
      } else {
        notifications.show({
          color: "red",
          message: res.message,
        });
      }
    } catch {
      notifications.show({
        color: "red",
        message: "Failed to update menu order. Please try again.",
      });
    }
  };

  return (
    <Box>
      <MenuModal reload={refetch} ref={modalRef} />

      <CustomTable
        isDashboardTable={false}
        loading={UpdateLoading ||isLoading }
        columns={tableColumns}
        data={menuData}
        grid={false}
        DragTable={true}
        onUpdateMenuOrder={handleUpdateMenuOrder}
        headerProps={{
          search: false,
          rightComponent: (
            <Box onClick={() => modalRef.current?.toggleModal()} my={6}>
              <ThemeButton
                title="Add Menu"
                mr={15}
                leftIcon={<IconPlus size={20} />}
              />
            </Box>
          ),
        }}
      />
    </Box>
  );
};

export default Menu;
