import {
  Box,
  Button,
  Checkbox,
  Modal,
  Text,
  createStyles,
} from "@mantine/core";
import { useToggle } from "@mantine/hooks";
import { useForm, yupResolver } from "@mantine/form";
import React, {
  memo,
  useCallback,
  forwardRef,
  useImperativeHandle,
  ForwardedRef,
  useState,
  useEffect,
} from "react";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { notifications } from "@mantine/notifications";
import { initialValue } from "../../../form/initial-value";
import { validations } from "../../../form/validation";
import InputField from "../../../component/form/input-field/InputField";
import { useAddMenuMutation } from "../../../hooks/menu/mutation/addMenu.mutation";
import { useUpdateMenu } from "../../../hooks/menu/mutation/updateMenu.mutation";
import { TMenuValues } from "../../../form/initial-value/menu.values";
import { IconTrash, IconGripVertical } from "@tabler/icons-react";

export interface IMenuModalRef {
  toggleModal: () => void;
  updateData: (menu: TMenuValues) => void;
}

interface IMenuModalProps {
  reload: () => void;
}

const MenuModal = (
  props: IMenuModalProps,
  ref: ForwardedRef<IMenuModalRef>
) => {
  const [data, setData] = useState<TMenuValues>();

  const { reload } = props;
  const [opened, toggle] = useToggle();
  const { classes } = useStyles();
  const { isLoading: addLoading, mutateAsync: addMenu } = useAddMenuMutation();
  const { isLoading: updateLoading, mutateAsync: updateMenu } = useUpdateMenu();

  const { getInputProps, onSubmit, reset, setValues, values } = useForm({
    initialValues: initialValue.AddMenuValues,
    validate: yupResolver(validations.menu),
    validateInputOnBlur: true,
    validateInputOnChange: true,
  });

  useEffect(() => {
    if (data) {
      setValues(data);
    } else {
      setValues(initialValue.AddMenuValues);
    }
  }, [setValues, data]);

  const handleFormSubmit = useCallback(
    async (values: TMenuValues) => {
      const res = data ? await updateMenu(values) : await addMenu(values);
      if (res.status === "success") {
        reload();
        toggle();
        reset();
        notifications.show({
          color: "green",
          message: res.message,
        });
      } else {
        notifications.show({
          color: "red",
          message: res.message,
        });
      }
    },
    [addMenu, toggle, reload, reset, updateMenu, data]
  );

  const handleCloseModal = useCallback(() => {
    toggle();
    setData(undefined);
  }, [toggle]);

  useImperativeHandle(
    ref,
    () => ({
      toggleModal: handleCloseModal,
      updateData: (menu) => setData(menu),
    }),
    [handleCloseModal]
  );

  const handleDragEnd = ({ destination, source }: any) => {
    if (!destination) return;

    setValues((prev) => {
      const reorderedSubmenu = [...(prev.submenu ?? [])];
      const [movedItem] = reorderedSubmenu.splice(source.index, 1);
      reorderedSubmenu.splice(destination.index, 0, movedItem);
      return { ...prev, submenu: reorderedSubmenu };
    });
  };

  return (
    <Modal
      styles={{
        title: { fontSize: "1.3rem", fontWeight: 500 },
        close: {
          color: "#ff008a",
          "&:hover": {
            backgroundColor: "#ff008a",
            color: "white",
            transition: "all 0.2s ease-in-out 0s",
          },
        },
        header: {
          zIndex: 1,
        },
      }}
      opened={opened}
      onClose={handleCloseModal}
      title={data ? "Edit Menu" : "Add Menu"}
      centered
      fullScreen
    >
      <form onSubmit={onSubmit(handleFormSubmit)}>
        <InputField label="Title" name="title" getInputProps={getInputProps} />
        <InputField label="Link" name="link" getInputProps={getInputProps} />
        <InputField label="Order" name="order" getInputProps={getInputProps} />
        <Checkbox
          size="md"
          label="Open In New Tab"
          mt={12}
          {...getInputProps("openInNewTab", { type: "checkbox" })}
        />

        <Box>
          <Text mb={15} style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}>
            Submenu
          </Text>
          <Box>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="submenu-list">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {values.submenu?.map((_, index) => (
                      <Draggable
                        key={index}
                        draggableId={`submenu-${index}`}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <Box
                            {...provided.draggableProps}
                            ref={provided.innerRef}
                            className={classes.submenuItem}
                            style={{
                              ...provided.draggableProps.style,
                              background: snapshot.isDragging
                                ? "#f0f0f0"
                                : "white",
                              cursor: "grab",
                            }}
                          >
                            <Box
                              {...provided.dragHandleProps}
                              className={classes.dragHandle}
                            >
                              <IconGripVertical size={18} />
                            </Box>
                            <InputField
                              name={`submenu.${index}.title`}
                              getInputProps={getInputProps}
                              placeHolder="Enter Title"
                            />
                            <InputField
                              name={`submenu.${index}.link`}
                              getInputProps={getInputProps}
                              placeHolder="Enter Link"
                            />
                            <Checkbox
                              size="md"
                              label="Open In New Tab"
                              mt={6}
                              {...getInputProps(`submenu.${index}.openInNewTab`, {
                                type: "checkbox",
                              })}
                            />
                            <Button
                              mt={6}
                              size="xs"
                              color="red"
                              onClick={() => {
                                setValues((prev) => ({
                                  ...prev,
                                  submenu:
                                    prev.submenu?.filter(
                                      (_, i) => i !== index
                                    ) ?? [],
                                }));
                              }}
                            >
                              <IconTrash size={18} />
                            </Button>
                          </Box>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Box>

          <Button
            mt={16}
            onClick={() =>
              setValues((prev) => ({
                ...prev,
                submenu: [
                  ...(prev.submenu ?? []),
                  { id: `${values.submenu?.length ?? 0}`, title: "", link: "" },
                ],
              }))
            }
          >
            Add Submenu
          </Button>
        </Box>

        <Button
          disabled={addLoading || updateLoading}
          loading={addLoading || updateLoading}
          type="submit"
          fullWidth
          mt="xl"
          className={classes.btn}
        >
          {data ? "Update Menu" : "Add Menu"}
        </Button>
      </form>
    </Modal>
  );
};

const useStyles = createStyles(() => ({
  btn: {
    background: "#ff008a",
    height: "45px",
    "&:hover": {
      backgroundColor: "#ff008a",
      color: "white",
    },
  },
  submenuItem: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    marginBottom: "10px",
  },
  dragHandle: {
    cursor: "grab",
    display: "flex",
    alignItems: "center",
  },
}));

export default memo(forwardRef(MenuModal));
