export type TMenuValues = {
  _id?: string;
  title: string;
  link: string;
  order?:number;
  openInNewTab?:boolean
  submenu?: {
    id: string;
    title: string;
    link: string;
    openInNewTab?:boolean
  }[];
};

export const AddMenuValues: TMenuValues = {
  _id: "",
  title: "",
  link: "",
  submenu: [],
  openInNewTab:false
};

export type TMenuOrder ={
  _id?:string,
  order:number
}
