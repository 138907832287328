import React, { FC, useState } from "react";
import {
  Navbar,
  createStyles,
  getStylesRef,
  Box,
} from "@mantine/core";
import { Link, useLocation } from "react-router-dom";
import navData from "./navData";
import { navLinkData } from "./navLinkData";
import AccordionLink from "./AccordionLink";
import { TablerIconsProps } from "@tabler/icons-react";
import navBottomData from "./navBottomData";

interface Props {
  burgerOpen: boolean;
}
const Nav: FC<Props> = ({ burgerOpen }) => {
  const location = useLocation();

  const [activeAccordion, setActiveAccordion] = useState<string | null>("HR");
  const { classes, cx } = useStyles();

  const links = (
    navData: {
      link: string;
      label: string;
      icon: (props: TablerIconsProps) => JSX.Element;
    }[]
  ) =>
    navData.map((item) => (
      <Link
        className={cx(classes.link, {
          [classes.linkActive]: item.link === location.pathname,
        })}
        to={item.link}
        key={item.label}
      >
        {item.link === location.pathname ? (
          <>
            <Box className={classes.activeIcon}></Box>
            <item.icon className={classes.linkIconActive} stroke={1.5} />
            <span>{item.label}</span>
          </>
        ) : (
          <>
            <item.icon className={classes.linkIcon} stroke={1.5} />
            <span>{item.label}</span>
          </>
        )}
      </Link>
    ));
  return (
    <Navbar
      mah={1000}
      maw=" 810px"
      width={{ 75: burgerOpen ? 75 : 300 }}
      className={classes.scrollbarHidden}
      sx={{
        overflow: "auto",
        transition: "width 300ms ease, min-width 300ms ease",
      }}
      style={{
        boxShadow: "5px 10px 15px 2px  #e4e5e6",
      }}
      zIndex={99}
    >

      <Navbar.Section mah={1000} w={300}>
        {links(navData)}
      </Navbar.Section>

      <AccordionLink
        burgerOpen={burgerOpen}
        topNavLinkData={navLinkData}
        activeAccordion={activeAccordion}
        setActiveAccordion={setActiveAccordion}
      />
      <Navbar.Section mah={1000} w={300}>
        {links(navBottomData)}
      </Navbar.Section>
    </Navbar>
  );
};

const useStyles = createStyles((theme) => ({
  scrollbarHidden: {
    overflowY: "auto",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },

  link: {
    ...theme.fn.focusStyles(),
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    fontSize: theme.fontSizes.sm,
    color: "#5b5e65",
    marginBottom: ".2rem",
    height: 45,
    padding: `${theme.spacing.sm} ${theme.spacing.xs}`,
    fontWeight: 700,

    "&:hover": {
      backgroundColor: "#ff008a",
      color: "white",
      transition: " all 0.2s ease-in-out 0s;",

      [`& .${getStylesRef("icon")}`]: {
        color: "white",
      },
      [`& .${getStylesRef("activeIcon")}`]: {
        color: "#ff008a",
        backgroundColor: "#ff008a",
      },
    },
  },
  activeIcon: {
    ref: getStylesRef("activeIcon"),
    width: "0.2rem",
    backgroundColor: "white",
    height: "2rem",
  },

  navLinkText: {
    fontSize: 14,
    fontWeight: 700,
    color: "#5b5e65",
    "&:hover": {
      backgroundColor: "#ff008a",
      color: "white",
      transition: " all 0.2s ease-in-out 0s;",

      [`& .${getStylesRef("icon")}`]: {
        color: "white",
      },
      [`& .${getStylesRef("activeIcon")}`]: {
        color: "#ff008a",
        backgroundColor: "#ff008a",
      },
    },
  },

  navLinkIcon: {
    ref: getStylesRef("icon"),
    color: "#ff008a",
    marginRight: theme.spacing.xs,
    marginLeft: "0.7rem",
  },

  linkIcon: {
    ref: getStylesRef("icon"),
    color: "#ff008a ",
    marginRight: theme.spacing.xl,
    marginLeft: "1.2rem ",
  },

  linkIconActive: {
    ref: getStylesRef("linkIconActive"),
    color: "#ff008a",
    marginRight: theme.spacing.xl,
    marginLeft: "1rem ",
  },
  linkActive: {
    "&, &:hover": {
      backgroundColor: "#ff008a",
      color: "white",
      [`& .${getStylesRef("icon")}`]: {
        color: "white",
      },
      [`& .${getStylesRef("linkIconActive")}`]: {
        color: "white",
      },
      [`& .${getStylesRef("activeIcon")}`]: {
        color: "white",
        backgroundColor: "white",
      },
    },
  },
}));

export default Nav;
