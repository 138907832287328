import { authUrls } from "./auth.url";
import { employeeUrls } from "./employee.url";
import { attendanceUrls } from "./attendance.url";
import { settingsUrl } from "./settings.url";
import { qrUserUrls } from "./qruser";
import { holidayUrls } from "./holiday.url";
import { leaveUrls } from "./leave.url";
import { projectsUrls } from "./projects.url";
import { feedbackUrls } from "./feedback.url";
import { reportUrls } from "./reports.url";
import { empMappingUrls } from "./empMapping.url";
import { notificationUrls } from "./notification.url";
import { projectApiUrls } from "./projectApi.url";
import { taskStatusUrls } from "./taskStatus.url";
import { leavePolicyUrls } from "./leavePolicy.url";
import { empWorksUrls } from "./empWork.url";
import { projectWorksUrls } from "./projectWork.url";
import { storyUrls } from "./story.url";
import { sprintUrls } from "./sprint.url";
import { emailSendUrls } from "./emailSend.url";
import { NotificationSendUrls } from "./notificationSend.url";
import { empPointUrls } from "./empPoint.url";
import { workingHourLoss } from "./workingLossHour.url";
import { menuUrls } from "./menu.url";

export const apiUrls = {
  ...authUrls,
  ...employeeUrls,
  ...attendanceUrls,
  ...settingsUrl,
  ...qrUserUrls,
  ...holidayUrls,
  ...leaveUrls,
  projects: projectsUrls,
  feedback: feedbackUrls,
  empMapping: empMappingUrls,
  reports: reportUrls,
  notification: notificationUrls,
  projectApi: projectApiUrls,
  taskStatus: taskStatusUrls,
  leavePolicy: leavePolicyUrls,
  empWorks: empWorksUrls,
  projectWorks: projectWorksUrls,
  sprint: sprintUrls,
  story: storyUrls,
  emailSend: emailSendUrls,
  notificationSend: NotificationSendUrls,
  empPoint: empPointUrls,
  GET_EMP_WORK_HRS_LOSS: "/admin/emp/work-hour-loss",
  workingHourLoss: workingHourLoss,
  menu:menuUrls
};
