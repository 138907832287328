import { useQuery } from "react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../api-urls";

const getMenu = async () => {
  const response: TServerResponse<TMenuData[], unknown> = await request({
    url: apiUrls.menu.GET_ALL_MENU,
    method: "GET",
  });
  return response;
};

export const useGetMenu = () => {
  return useQuery(["admin", "get-menu"], getMenu);
};
