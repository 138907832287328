import { ResetIniValues } from "./Reset.values";
import { loginIniValues } from "./login.values";
import { forgotPasswordValues } from "./forgotPassword.values";
import { AddStudentValues } from "./addStudent.values";
import { settingsIniValues } from "./settings.values";
import { AddProjectsValues } from "./addProjects.values";
import { AddFeedbackValues } from "./addFeedback.values";
import { BirthdayIniValues } from "./birthdayMessage.value";
import { AddEmpMappingValues } from "./addEmpMapping.values";
import { AddStoryValues } from "./story.values";
import { AddSprintValues } from "./sprint.values";
import { AddMenuValues } from "./menu.values";
import { AddEmpPointValues } from "./empPoint.values";
export const initialValue = {
  loginIniValues,
  forgotPasswordValues,
  ResetIniValues,
  AddStudentValues,
  settingsIniValues,
  AddProjectsValues,
  AddFeedbackValues,
  BirthdayIniValues,
  AddEmpMappingValues,
  AddStoryValues,
  AddSprintValues,
  AddMenuValues,
  AddEmpPointValues
};
