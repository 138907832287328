import React, { memo } from "react";
import { Box, createStyles } from "@mantine/core";

import DynamicTable from "./DynamicTable";
import DragDynamicTable from "./DragDynamicTable";

const TableList: React.FC<TTableListView> = ({
  columns = [],
  data = [],
  DragTable = false,
  onUpdateMenuOrder
}) => {
  const { classes } = useStyles();
  

  return (
    <Box>
      <div className={classes.tableContainer}>
        {DragTable ? (
          <DragDynamicTable data={data} columns={columns} onUpdateMenuOrder={onUpdateMenuOrder} />
        ) : (
          <DynamicTable data={data} columns={columns}  />
        )}
      </div>
    </Box>
  );
};

const useStyles = createStyles(() => ({
  tableContainer: {
    border: "1px solid #D3D3D3",
    marginTop: 25,
    borderRadius: 5,
    width: "100%",
    overflow: "auto",
  },

  pagination: {
    display: "flex",
    marginTop: 25,
  },
}));

export default memo(TableList);
