import React, { memo, useEffect, useState } from "react";
import { Table } from "@mantine/core";
import NoData from "../../../no-data/NoData";
import { useLocation } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { IconGripVertical } from "@tabler/icons-react";

const DragDynamicTable: React.FC<TTableListView> = ({ data = [], columns ,onUpdateMenuOrder}) => {
  const [tableData, setTableData] = useState(data);

  useEffect(() => {
    setTableData(data);
  }, [data]);

  const route = useLocation();

  const headerStyles = () => {
    return {
      backgroundColor: "white",
      color: "#000",
    };
  };


  const handleDragEnd = async (result: any) => {
    if (!result.destination) return;

    const reorderedData = [...tableData];
    const [movedRow] = reorderedData.splice(result.source.index, 1);
    reorderedData.splice(result.destination.index, 0, movedRow);

    setTableData(reorderedData as any[]);

    const updatedOrder = reorderedData
    .map((item, index) => ({
      _id: item._id,
      order: index + 1,
    }))
    .filter((item): item is { _id: string; order: number } => Boolean(item._id)); 

    if (onUpdateMenuOrder) {
        console.log("onUpdateMenuOrder 1",onUpdateMenuOrder);
        await onUpdateMenuOrder(updatedOrder);
      }
    
  };

  if (tableData.length === 0) {
    return <NoData title="No Data Found" />;
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Table
        horizontalSpacing="xl"
        verticalSpacing="md"
        highlightOnHover
        h={60}
        bg={route.pathname === "/" ? "#fff" : "transparent"}
        style={{ justifyContent: "space-between" }}
      >
        <thead>
          <tr>
            <th style={headerStyles()}></th>
            {columns.map((column) => (
              <th
                style={{
                  ...headerStyles(),
                  minWidth: column.minWidth,
                }}
                key={column.key}
              >
                {column.header}
              </th>
            ))}
          </tr>
        </thead>
        <Droppable droppableId="table-body">
          {(provided) => (
            <tbody {...provided.droppableProps} ref={provided.innerRef}>
              {tableData.map((row: any, index) => (
                <Draggable key={row._id} draggableId={row._id} index={index}>
                  {(provided, snapshot) => (
                    <tr
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={{
                        ...provided.draggableProps.style,
                        background: snapshot.isDragging ? "#f0f0f0" : "white",
                      }}
                    >
                      <td
                        {...provided.dragHandleProps}
                        style={{ cursor: "grab", padding: "0 10px" }}
                      >
                        <IconGripVertical size={20} />
                      </td>
                      {columns.map((column) => (
                        <td
                          key={column.key}
                          style={{ color: "#000", fontWeight: 400 }}
                        >
                          {column.renderCell
                            ? column.renderCell(row)
                            : row[column.key]}
                        </td>
                      ))}
                    </tr>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </tbody>
          )}
        </Droppable>
      </Table>
    </DragDropContext>
  );
};

export default memo(DragDynamicTable);
