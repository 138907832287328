import { apiUrls } from "../../api-urls";
import { request } from "../../../services/axios.service";
import { useMutation } from "react-query";

const deleteMenu = async (_id: string) => {
  const response: TServerResponse<unknown, unknown> = await request({
    url: apiUrls.menu.DELETE_MENU,
    method: "POST",
    data: { _id },
  });
  return response;
};

export const useDeleteMenu = () => {
  return useMutation({ mutationFn: deleteMenu });
};
