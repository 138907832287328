import { object, string, array, number } from "yup";

export const menuValidationSchema = object({
  title: string().required("Title is required"),
  link: string().required("Link is required"),
  order: number()
    .required("Order is required")
    .min(1, "Order must be at least 1"),
  submenu: array().of(
    object({
      id: string().required("Submenu ID is required"),
      title: string().required("Submenu title is required"),
      link: string().required("Submenu link is required"),
    })
  ),
});
