import { Box,  Text } from "@mantine/core";
import React from "react";

export const MENU_COLUMNS: TMenuTableColumns[] = [
  { key: "title", header: "Title" },
  { key: "link", header: "Link" },
  { key: "order", header: "Order" },
  {
    key: "submenu",
    header: "Submenu Title",
    renderCell: (row: TMenuData) => {
      const { submenu } = row;
      return (
        <div>
          {submenu?.map((item, index) => (
            <Box key={item.title + index}>
              <Text>{item.title}</Text>
            </Box>
          ))}
        </div>
      );
    },
  },
];
