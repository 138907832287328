import {
  createStyles,
  Header,
  Group,
  Burger,
  rem,
  Paper,
  Transition,
  Menu,
  Box,
  Title,
  UnstyledButton,
  Avatar,
  Text,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import {
  IconLogout,
  IconMenu2,
  IconSettings,
  IconUser,
} from "@tabler/icons-react";
import React, { memo, useMemo } from "react";
import IMAGES from "../../images/index";
import { LuBriefcase, LuBell } from "react-icons/lu";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useLogoutMutation } from "../../hooks/auth/mutation/useLogout.mutation";
import TopHeader from "../top-header/TopHeader";
import { useGetMenu } from "../../hooks/menu/query/getMenu.query";
import { useAppSelector } from "../../app/hooks";

const useStyles = createStyles((theme) => ({
  header: {
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
  },

  inner: {
    height: rem(57),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  innerDiv1: {
    alignItems: "center",
    display: "flex",
  },
  innerDiv2: {
    display: "flex",
    float: "right",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "1.5rem",
  },
  innerDiv2Items: {
    width: "4rem",
    borderLeft: "solid 1px #80808040",
    padding: "0.8rem 1.3rem",
  },
  dpBox: {
    width: "4rem",
    borderLeft: "solid 1px #80808040",
    padding: "0.5rem 1.3rem",
  },
  burger: {
    borderRight: "solid 1px #80808040",
    padding: "0px 20px",
  },

  logo: {
    width: "14.05rem",
    borderRight: "solid 1px #80808040",
    padding: "7px 0px 7px 12px", 
    alignItems:"center",
  },

  imgLogo: {
    width: 40,
  },
  dp: {
    width: "2.3rem",
    border: "1px solid #F00F89",
    borderRadius: "10px",
    cursor: "pointer",
  },
  badge: {
    backgroundColor: "#00882E",
    display: "inline",
    height: "5px",
    width: "5px",
    color: "#fff",
    position: "fixed",
    right: "170px",
    top: "16px",
    borderRadius: "50%",
    padding: "0px",
  },

  profileMenu: {
    color: "green",
    fontSize: "0.1rem",
    borderRadius: "1rem",
  },
  profileList: {
    textDecoration: "none",
  },
  profileListItem: {
    fontSize: `${theme.fontSizes.xs}`,
    "&:hover": {
      transition: " all 0.3s ease-in-out 0s;",
      color: theme.colors.violet[6],
      backgroundColor: "white",
    },
  },
  headingContainer: {
    marginLeft: 40,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "row",
  },
  profilePicture: {
    border: "1px solid #F00F89",
  },
}));

type Props = {
  burgerState: () => void;
};
const HeaderSearch = (props: Props) => {
  const [opened, handlers] = useDisclosure(true);
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { mutateAsync } = useLogoutMutation();
  const { name } = useAppSelector((state) => state.userData);

  const handleLogout = async () => {
    const res = await mutateAsync();
    if (res.status === "success") {
      navigate("/login", { replace: true });
    }
  };
  const click = () => {
    props.burgerState();
    handlers.toggle();
  };

  const routeTitles: { [key: string]: string } = {
    "/": "Dashboard",
    "/today-attendance": "Today's Attendance",
    "/employees": "Employees",
    "/attendance": "Attendance",
    "/holidays": "Holidays",
    "/worklog": "Work Log",
    "/leave": "Leave Management",
    "/projects": "Projects",
    "/employ-mapping": "Employee Mapping",
    "/employ-feedback": "Employee FeedBack",
    "/task-report": "Task Report",
    "/notification-logs": "Notifications Log",
    "/timeline": "Timeline",
    "/project-api": "Project Api ",
    "/leave-policy": "Leave Policy ",
    "/employee-work": "Emp Work ",
    "/project-work": "Project Work ",
    "/sprint": "Sprint ",
    "/story": "Story ",
    "/work-log-api": "Work Log Api",
    "/sprint-api": "Sprint Api",
    "/email-send": "Email Send",
    "/email-send-logs": "Email Send Logs",
    "/notification-send": "Notification Send",
    "/notification-send-logs": "Notification Send Logs",
    "/employee-point": "Employee Point",
    "/office-hours-loss": "Office Hours Loss",
    "/menu": "Menu",
    "/project-info/:id": "Project Info",
    "/project-task": "Project Tasks",
  };

  const location = useLocation();
  const currentPath: string = location.pathname;

  let dynamicTitle = routeTitles[currentPath];

  if (currentPath.startsWith("/project-sprint/")) {
    dynamicTitle = "Project Sprint";
  } else if (currentPath.startsWith("/project-story/")) {
    dynamicTitle = "Project Story";
  }

  const currentTitle: string = dynamicTitle || "";

  const { data, isLoading } = useGetMenu();

  const menuData: TMenuData[] = useMemo(() => {
    if (!isLoading && data && data.data) {
      return data.data.map((menu) => ({
        ...menu,
      }));
    } else {
      return [];
    }
  }, [isLoading, data]);

  return (
    <Paper shadow="md">
      <Header
        height={menuData.length ? 90 : 57}
        className={classes.header}
        p={0}
      >
        {menuData.length > 0 && <TopHeader menuData={menuData} />}
        <Box className={classes.inner}>
          <Box className={classes.innerDiv1}>
            <Group className={classes.burger}>
              <Burger
                color="#ff008a"
                opened={opened}
                onClick={() => click()}
                size="md"
              />
            </Group>
            {opened === true ? (
              <>
                <Transition
                  mounted={opened}
                  transition="scale-x"
                  duration={300}
                >
                  {(styles) => (
                    <Box style={styles}>
                      <UnstyledButton>
                        <Group className={classes.logo}>
                          <Avatar
                            src={IMAGES.profile}
                            size={40}
                            color="#ff008a"
                            radius="md"
                            className={classes.profilePicture}
                          ></Avatar>

                          <Box mt={5}>
                            <Text
                              weight="bold"
                              lineClamp={1}
                              size={"xs"}
                            >
                             {name}
                            </Text>
                            <Text size="sm" weight="bold" color="dimmed">
                              Admin
                            </Text>
                          </Box>
                        </Group>
                      </UnstyledButton>
                    </Box>
                  )}
                </Transition>
                <Box className={classes.headingContainer}>
                  <Title order={2} weight={500} c="gray">
                    {currentTitle}
                  </Title>
                </Box>
              </>
            ) : (
              <>
                <Transition
                  mounted={opened}
                  transition="scale-x"
                  duration={100}
                  timingFunction="ease"
                >
                  {(styles) => (
                    <Box style={styles}>
                      <Group className={classes.logo}>
                        <img
                          className={classes.imgLogo}
                          src={IMAGES.logo}
                          alt="img not found"
                        />
                      </Group>
                    </Box>
                  )}
                </Transition>
                <Box className={classes.headingContainer}>
                  <Title order={2} weight={500} c="gray">
                    {currentTitle}
                  </Title>
                </Box>
              </>
            )}
          </Box>

          <Box className={classes.innerDiv2}>
            <Box className={classes.innerDiv2Items}>
              <LuBell size={20} />
              {/* <span className={classes.badge}></span> */}
            </Box>
            <Box className={classes.innerDiv2Items}>
              <LuBriefcase size={20} />
            </Box>
            <Menu shadow="xl" offset={3} width={150}>
              <Menu.Target>
                <Box className={`${classes.innerDiv2Items} ${classes.dpBox}`}>
                  <img className={classes.dp} src={IMAGES.profile} alt="" />
                </Box>
              </Menu.Target>
              <Menu.Dropdown className={classes.profileMenu}>
                <Link to="students" className={classes.profileList}>
                  <Menu.Item
                    className={classes.profileListItem}
                    icon={<IconUser size={20} />}
                  >
                    Profile
                  </Menu.Item>
                </Link>
                <Link to="settings" className={classes.profileList}>
                  <Menu.Item
                    className={classes.profileListItem}
                    icon={<IconSettings size={20} />}
                  >
                    Settings
                  </Menu.Item>
                </Link>
                <Link to="menu" className={classes.profileList}>
                  <Menu.Item
                    className={classes.profileListItem}
                    icon={<IconMenu2 size={20} />}
                  >
                    Menu
                  </Menu.Item>
                </Link>
                <Box className={classes.profileList} onClick={handleLogout}>
                  <Menu.Item
                    className={classes.profileListItem}
                    icon={<IconLogout size={25} />}
                  >
                    Sign Out
                  </Menu.Item>
                </Box>
              </Menu.Dropdown>
            </Menu>
          </Box>
        </Box>
      </Header>
    </Paper>
  );
};
export default memo(HeaderSearch);
